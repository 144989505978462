:root {
   --color-telesalud: rgb(84, 61, 208);
}
.fadeIn {
   opacity: 1;
   transition: opacity 0.5s 0.5s;
}

.fadeOut {
   opacity: 0;
   transition: opacity 1s;
}

.border-tele {
   border: 1px solid var(--color-telesalud);
}

.bg-tele {
   background: var(--color-telesalud);
   color: white;
}
.text-tele{
   color: var(--color-telesalud);
   font-weight: 500;
}
.titulo-recurso {
   font-size: 1.2rem;
   color: var(--color-telesalud);
}

.descripcion-recurso {
   font-size: 0.75rem;
   text-align: justify;
}

.img-recurso {
   width: 100px;
   min-height: 120px;
}

.recurso_sidebar {
   background-color: rgb(238, 238, 238);
   color: white;
   display: block;
   flex-direction: column;
   height: 100vh;
   padding-left: 0px;
   padding-right: 5px;
   width: 100%;
   height: auto;
}
.recursos {
   display: flex;
   overflow-x: scroll;
   padding: 0px 0px 10px 0px;
   margin: 5px;
}
.recursos::-webkit-scrollbar {
   display: block !important; /* mostrar scroll */
}
.recurso_entrada {
   cursor: pointer;
   background-color: white;
   border-radius: 4px;
   color: black;
   display: flex;
   margin: 5px;
   overflow: hidden;
   min-width: 170px;
}

.recurso-body {
   width: 100%;
   height: 100%;
}

.recurso-title {
   font-size: 1rem;
   font-weight: bold;
}

.recurso-content {
   font-size: 0.8rem;
}

.pointer {
   cursor: pointer;
}

.footer-tele {
   color: var(--color-telesalud);
   font-size: 0.75rem;
}

.asignar_recursos {
   width: 80%;
}

.asign-block2 {
   position: relative;
   bottom: 0px;
   right: 0px;
   left: 20px;
   width: 100%;
}

.recurso_main {
   background-color: rgb(255, 255, 255);
   color: rgb(15, 15, 15);
   display: flex;
   flex-direction: column;
   padding-left: 20px;
   padding-right: 0px;
   width: 100%;
}

.recursos_form {
   flex: 1 1 auto;
   overflow: scroll;
}

.iniciar-teleconsulta {
   padding: 3px !important;
}
.zoom {
   zoom: 80%;
}

.zoom_label {
   zoom: 85%;
   color: rgb(19, 19, 19);
}

.form-control-tele {
   display: block;
   width: 100%;
   height: calc(2.5em + 0.75rem + 2px);
   padding: 0.475rem 0.75rem;
   font-size: 1rem;
   font-weight: 500;
   line-height: 1.5;
   color: var(--color-telesalud);
   background-color: #adb5bd1c;
   background-clip: padding-box;
   border: 0.01px solid #c4c4c456;
   border-radius: 0.5rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea.form-control-tele {
   min-height: 190px;
   font-weight: 500;
   resize: none;
}
select.form-control-tele > option {
   color: var(--color-telesalud);
   font-weight: bold;
}
.form-control-tele:focus {
   color: #363636;
   background-color: #fff;
   border-color: var(--color-telesalud);
   outline: 1;
   box-shadow: 0 0 0 0.2rem rgba(68, 0, 255, 0.137);
}
.hidden {
   display: none;
}
